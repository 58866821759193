import styled from 'styled-components'


export const Client = styled.div`
    float: left;
    min-width: 49%;
    height: 210px;
    background: #F4F5F6;
    border: 1px solid #eee;
    border-radius: 6px;
    margin-right: 2%;
    padding: 10px;
`

export const Block = styled.dl`

    dt {
        float: left;
        min-height: 18px;
        margin-right: 6px;
    }

    dd {
        display: block;
        min-height: 18px;
        margin-bottom: 8px;
    }

`
