import { Client, Block } from "./styles";

interface IDados {
  id: number;
  nomeCliente: string;
  dddCelular: string;
  celular: string;
  email: string;
  situacaoCrm: string;
  protocoloAtendimento: string;
  codigoPedido: number;
  codigoPedidoMagento: number;
}

interface IAtendimento {
  atendimento: IDados;
}

export function InformacoesCrm({ atendimento }: IAtendimento) {
  return (
    <Client>
      <Block>
        <dt>Cliente:</dt>
        <dd>{atendimento.nomeCliente}</dd>

        <dt>Telefone:</dt>
        <dd>{`(${atendimento.dddCelular}) ${atendimento.celular}`}</dd>

        <dt>E-mail:</dt>
        <dd>{atendimento.email}</dd>

        <dt>Situação:</dt>
        <dd>{atendimento.situacaoCrm}</dd>

        <dt>Código do pedido:</dt>
        <dd>{atendimento.codigoPedidoMagento}</dd>

        <dt>Protocolo:</dt>
        <dd>{atendimento.protocoloAtendimento}</dd>
      </Block>
    </Client>
  );
}
