import Resizer from "react-image-file-resizer";

export const redimensionaImagem = (file: Blob, extensao: string) =>
  new Promise<File>((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      extensao,
      100,
      0,
      (uri) => {
        resolve(uri as File);
      },
      "file"
    );
  });
