import { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/auth";

export const SignIn: React.FC = () => {
  const { signIn } = useAuth();
  const location = useLocation().search;

  const query = useMemo(() => {
    return new URLSearchParams(location);
  }, [location]);

  const getUrlAuth = useCallback(() => {
    const token = query.get("access_token");

    let urlPath = localStorage.getItem("@HavanLabs:redirectUrl") || "";

    if (!token) {
      const webUrl = process.env.REACT_APP_WEB;
      const authUrl = process.env.REACT_APP_AUTH_URL;

      window.location.href = `${authUrl}/AutenticacaoApi/Entrar?ReturnUrl=${webUrl}/&RedirectUrl=${webUrl}${urlPath}`;
      return null;
    }

    signIn(token);
    window.location.href = urlPath;
  }, [query, signIn]);

  useEffect(() => {
    getUrlAuth();
  }, [getUrlAuth]);

  return null;
};
