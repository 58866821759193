import {
  Footer,
  Navbar,
  PageBody,
  Profile,
  Sidebar,
  ThemeProvider,
} from "@havan/react-components";
import { BrowserRouter } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";
import { ThemeProvider as ThemeMaterialUI, createTheme } from "@mui/material/styles";
import { ptBR } from "@mui/material/locale";

import { useAuth } from "./hooks/auth";
import Routes from "./routes";

import { GlobalStyle } from "./styles/global";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");


function App() {
  const { signOut, user } = useAuth();

  if (!user) {
    return (
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    );
  }

  const theme = createTheme(
    {
      components: {
        MuiTablePagination: {
          styleOverrides: {
            root: {
              alignItems: "center",
              justifyContent: "center",
            },
            selectLabel: {
              marginTop: "auto",
            },
            displayedRows: {
              marginTop: "auto",
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              "&:hover": {
                backgroundColor: "#e6e6e6",
              },
              backgroundColor: "#f5f5f5",
            },
          },
        },
        MuiTableHead: {
          styleOverrides: {
            root: {
              backgroundColor: "#FFFFFF",
            },
          },
        },
      },
    },
    ptBR
  );

  return (
    <BrowserRouter>
      <ThemeMaterialUI theme={theme}>
        <ThemeProvider>
          <GlobalStyle />
          <PageBody>
            <Navbar signOut={signOut}>
              <Profile user={user}>
                <button type="button" title="Sair do sistema" onClick={signOut}>
                  <FaSignOutAlt /> Logout
                </button>
              </Profile>
            </Navbar>
            <Sidebar
              list={[
                { id: 1, label: "Dashboard", to: "/dashboard" },
                {
                  id: 2,
                  label: "Atendimentos",
                  to: "/atendimento-crm/juridico",
                },
              ]}
            />
            <Routes />
            <Footer />
          </PageBody>
        </ThemeProvider>
      </ThemeMaterialUI>
    </BrowserRouter>
  );
}

export default App;
