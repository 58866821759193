import styled from "styled-components";
import fundo from "../../../assets/bg-liberdade.png"

export const BackgroundLiberdade = styled.body`
    display: table;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-image: url(${fundo});
    background-repeat: no-repeat;
    background-size: auto 100%;
`

export const NoHashContainer = styled.div`
    width: 80%;
    background: #EEF8FF;
    text-align: center;
    padding: 10px;
    margin: auto;

`

export const Container = styled.div`
   padding: 50px 50px 50px;

   @media(max-width: 500px){
    .content_client{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
`

export const Grid = styled.div`
    float: left;
    min-width: 49%;
    height: 210px;
    background: #f4f5f6;
    border: 1px solid #eee;
    border-radius: 6px;

    h5 {
        margin: 5px 0 2px 5px;
    }

    a {
        display: none;
    }

    .hvn-grid {
        height: 165px;
        margin-top: 5px;
        overflow: auto;
        overflow-y: scroll;

        table {
            float: left;
            width: 100%;
            background: rgb(255, 255, 255);
            padding: 0px;
            border-width: 1px;
            border-style: solid;
            border-color: rgb(139, 157, 175);
            border-image: initial;

            thead {
                color: rgb(255, 255, 255);
                background: rgb(139, 157, 175);
                border-width: 1px;
                border-style: solid;
                border-color: rgb(139, 157, 175);
                border-image: initial;
            }

            tbody {
                display: table-row-group;
            }


        }
    }

    @media(max-width: 500px) {
      &{
        margin-top: 3rem;

        h5{
          text-align: center;
        }
      }
    }

`

export const Info = styled.div`
    white-space:pre-line;
    float: left;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 5px;
    padding:  10px;
    background: #EEF8FF;
    border: 1px solid #EEE;
    border-radius: 6px;
`
export const TabContainer = styled.div`
  margin-top: 250px;
  width: 100%;

  button.nav-link.active {
    background-color: #f2f2f2;
  }

  input[type='file']{
    display: none;
  }

  .btn-file{
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    border-radius: 6px;
    background-color: #215696;
    color: #FFF;
    margin-top: 0.5rem;

    cursor: pointer;
  }

  .secaoLista {
    width: 100%;
    padding: 0;
    overflow-y: auto;
    margin: 10px 0;
  }

  .novaAnotacao {
    margin-top: 20px;

    textarea {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 5px;
      height: 90px;
    }
  }

  .botaoConcluir{
    margin-top: 20px;
  }

  /* ANEXOS */
  .separador-anexo {
    margin-top: 15px;
    padding-top: 10px;
    border-top: 3px solid #ddd;
  }

  .titulo-anexo {
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: bold;
  }

  .separador-anexo:nth-of-type(1) {
    margin-top: 0;
  }
  strong{
      display: block;
      margin:  10px;
    }

  .secaoAnexos {
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 10px;


    .anexo {
      width: 22rem;
      height: 22rem;
      position: relative;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }

      .btnAnexo {
        position: absolute;
        right: -7px;
        height: 35px;
        width: 35px;
        padding: 0;

        i {
          margin: 0;
        }
      }

      &:hover{
          filter: brightness(0.9);
        }
    }
  }

  @media (max-width: 500px) {
      &{
        margin-top: 1rem;

        input{
          margin-top: 1rem;
          width: 100%;
        }
        legend{
          text-align: center;
        }
        .secaoAnexos{
          justify-content: center;

          .anexo{
            width: 200px;
            height: 200px;
          }
        }

        .btn-file{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          i{
            display: none;
          }
        }
      }
    }

`


export const AreaNovaAnotacao = styled.div`

    float: left;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 10px;

    legend {
        font: inherit;
        margin: 5px 0 2px 5px;
    }

    textarea {
        width: 100%;
    }

    input {
        float: right;
        border-radius: 8px;
        padding: 5px;
    }
`

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`

export const Carregando = styled.div`
  display: flex;
  margin-top: 350px;
  width: 100%;
  justify-content: center;

`
