import React, { useEffect, useCallback, useState, useRef } from "react";
import { PageContent } from "@havan/react-components";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
  TextField,
  Box,
  Button,
  // TODO - Caso queira voltar no futuro
  // FormControl,
  // InputLabel,
  // Select,
  // MenuItem,
} from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { ModalNotAuthorization, NotData } from "./UI";
import api from "../../../services/api";
import {
  ContainerFilter,
  // TODO - Caso queira voltar no futuro
  // SeparatorColor,
  // TextTableCriticidade,
  TitleTable,
} from "./styles";

interface IPropsJuridical {
  cliente?: string;
  // TODO - Caso queira voltar no futuro
  // criticidade?: string;
  dataTransferencia?: Date | null;
  pedidoCodigo?: string;
  protocolo?: string;
}

export const JuridicoListagem: React.FC = () => {
  const history = useHistory();

  const [openModal, setOpenModal] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [dataJuridical, setDataJuridical] = useState<IPropsJuridical[]>([]);
  const [errorDataJuridical, setErrorDataJuridical] = useState(false);
  const [loading, setLoading] = useState(true);
  const protocol = useRef<any>();
  const demand = useRef<any>();
  const client = useRef<any>();
  // TODO - Caso queira voltar no futuro
  // const criticality = useRef<any>();
  const [dateInitial, setDateInitial] = useState<Date | null | string>(
    moment().subtract(3, "month").set({ hour: 0, minute: 0, second: 0 }).format("YYYY-MM-DD")
  );
  const [dateEnd, setDateEnd] = useState<Date | null | string>(
    moment().format("YYYY-MM-DD")
  );

  const dateBeforeThreeMonths: any =
    moment().subtract(3, "month").set({ hour: 0, minute: 0, second: 0 });

  const getDataJuridical = useCallback(async () => {
    const isDateInitialMinorThreeMonths = moment(dateInitial).isBefore(
      dateBeforeThreeMonths.format("YYYY-MM-DD")
    );

    const isDateInitialLargerDateActualy = moment(dateInitial).isAfter(
      moment().format("YYYY-MM-DD")
    );

    const isDateEndLargerDateActualy = moment(dateEnd).isAfter(
      moment().format("YYYY-MM-DD")
    );

    if (
      isDateInitialMinorThreeMonths ||
      isDateInitialLargerDateActualy ||
      isDateEndLargerDateActualy
    ) {
      setOpenModal(true);
      return;
    }

    setErrorDataJuridical(false);
    setLoading(true);

    const dateInitialFormatted: Date = new Date(moment(dateInitial).format("YYYY-MM-DD 00:00:00"));
    const dateEndFormatted: Date = new Date(moment(dateEnd).format("YYYY-MM-DD 23:59:59"));
    const timezoneOffset: number = dateInitialFormatted.getTimezoneOffset() * 60000;
    const requestParams = {
      params: {
        protocolo: protocol?.current?.value,
        pedido: demand?.current?.value ? demand?.current?.value : 0,
        cliente: client?.current?.value,
        dataInicio: dateInitial ? new Date(dateInitialFormatted.getTime() - timezoneOffset) : new Date(),
        dataFim: dateEnd ? new Date(dateEndFormatted.getTime() - timezoneOffset) : new Date(),
        // TODO - Caso queira voltar no futuro
        // idCriticidade: Number(criticality?.current?.value),
        pagina: page,
        take: rowsPerPage,
      },
    };

    try {
      const response = await api.get(
        `atendimento-crm-juridico/obter-atendimentos-juridico`,
        requestParams
      );

      if (response) {
        setDataJuridical(response?.data?.dados);
        setTotalSize(response?.data?.totalRegistros);
      }
    } catch (error) {
      setErrorDataJuridical(true);
    } finally {
      setLoading(false);
    }
  }, [dateInitial, dateEnd, page, rowsPerPage]);

  useEffect(() => {
    getDataJuridical();
  }, [getDataJuridical]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChangeDateInitial = (newValue: string | Date | null) => {
    setDateInitial(newValue);
  };

  const handleChangeDateEnd = (newValue: Date | null) => {
    setDateEnd(newValue);
  };

  function handleToDetails(protocolo: string | undefined) {
    history.push(`/atendimento-crm/juridico/${protocolo}`);
  }

  const handleSearchJuridical = (event: any) => {
    event.preventDefault();
    getDataJuridical();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <PageContent title="Atendimento Jurídico">
        <ContainerFilter>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSearchJuridical}
          >
            <TextField
              className="simple-textfield"
              id="outlined-basic"
              label="Protocolo"
              variant="outlined"
              size="small"
              inputRef={protocol}
              defaultValue=""
            />
            <TextField
              className="simple-textfield"
              id="outlined-basic"
              label="Pedido"
              variant="outlined"
              size="small"
              inputRef={demand}
            />
            <TextField
              className="client-textfield"
              id="outlined-basic"
              label="Cliente"
              variant="outlined"
              size="small"
              inputRef={client}
              defaultValue=""
            />
            <DesktopDatePicker
              minDate={dateBeforeThreeMonths}
              disableFuture={true}
              label="Data início"
              inputFormat="DD/MM/YYYY"
              value={dateInitial}
              onChange={handleChangeDateInitial}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  className="date-textfield"
                />
              )}
            />
            <DesktopDatePicker
              minDate={dateBeforeThreeMonths}
              disableFuture={true}
              label="Data fim"
              inputFormat="DD/MM/YYYY"
              value={dateEnd}
              onChange={handleChangeDateEnd}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  className="date-textfield"
                />
              )}
            />
            {/*
              // TODO - Caso queira voltar no futuro
            <FormControl style={{ width: 180 }} size="small">
              <InputLabel id="demo-select-small">Criticidade</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                variant="outlined"
                inputRef={criticality}
                defaultValue={0}
              >
                <MenuItem value={0}>
                  <em>Todas</em>
                </MenuItem>
                <MenuItem value={1}>Baixa</MenuItem>
                <MenuItem value={2}>Média</MenuItem>
                <MenuItem value={3}>Alta</MenuItem>
              </Select>
            </FormControl> */}
          </Box>
        </ContainerFilter>
        <Button
          variant="contained"
          style={{ width: 180, marginBottom: 24, marginLeft: 8 }}
          onClick={getDataJuridical}
        >
          Pesquisar
        </Button>
        <TableContainer component={Paper}>
          {(!loading && errorDataJuridical) ||
            (!loading && dataJuridical.length === 0) ? (
            <NotData />
          ) : (
            <>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TitleTable>Protocolo</TitleTable>
                    </TableCell>
                    <TableCell>
                      <TitleTable>Pedido</TitleTable>
                    </TableCell>
                    <TableCell>
                      <TitleTable>Cliente</TitleTable>
                    </TableCell>
                    <TableCell>
                      <TitleTable>Data de transferência</TitleTable>
                    </TableCell>
                    {/*
                    // TODO - Caso queira voltar no futuro
                    <TableCell>
                      <TitleTable>Criticidade</TitleTable>
                    </TableCell> */}
                    <TableCell> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataJuridical?.map((row) => (
                    <TableRow
                      key={row?.protocolo}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row?.protocolo}
                      </TableCell>
                      <TableCell>{row?.pedidoCodigo}</TableCell>
                      <TableCell>{row?.cliente}</TableCell>
                      <TableCell>
                        {row?.dataTransferencia
                          ? moment(row?.dataTransferencia).format("L")
                          : "-"}
                      </TableCell>
                      {/*
                      // TODO - Caso queira voltar no futuro
                      <TableCell align="left">
                        <TextTableCriticidade>
                          {row?.criticidade}
                          <SeparatorColor criticidade={row?.criticidade} />
                        </TextTableCriticidade>
                      </TableCell> */}
                      <TableCell>
                        <IconButton
                          onClick={() => handleToDetails(row?.protocolo)}
                          aria-label={`Ver detalhes - ${row?.protocolo}`}
                        >
                          <KeyboardArrowRight color="primary" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalSize}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Linhas por página"
              />
            </>
          )}
        </TableContainer>
      </PageContent>
      <ModalNotAuthorization
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </LocalizationProvider>
  );
};
