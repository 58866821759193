import { PageContent } from "@havan/react-components";
import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";

import { useAuth } from "../../../hooks/auth";
import api from "../../../services/api";
import { criarNome } from "../../../helpers/util";

import { IAtendimentoCrmJuridico } from "../../../class/IAtendimentoCrmJuridico";
import { IApiResponse } from "../../../class/IApiResponse";
import { CardAtendimento } from "../../../components/CardAtendimento";
import { LoadingButton } from "../../../components/LoadingButton";
import { ZoomModal } from "../../../components/ZoomModal";

import { Container, Anotacao } from "./styles";
import "react-toastify/dist/ReactToastify.min.css";

interface JuridicoParams {
  protocolo?: string;
}

export const Juridico: React.FC = () => {
  const OCORRENCIA_JURIDICO_URL =
    "atendimento-crm-juridico/obter-ocorrencia-juridico/";
  const ANEXO_URL = `${process.env.REACT_APP_URL}anexos/obter-anexo-crm/`;
  const ANEXO_SOL_TROCA_URL = `${process.env.REACT_APP_URL}anexos/obter-anexo-trocas/`;

  const { user } = useAuth();
  const { protocolo } = useParams() as JuridicoParams;
  const history = useHistory();

  const [carregando, setCarregando] = useState(true);
  const [atendimento, setAtendimento] = useState<IAtendimentoCrmJuridico>(
    {} as IAtendimentoCrmJuridico
  );
  const [novaAnotacao, setNovaAnotacao] = useState("");
  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);
  const [isAtendimentoFinalizado, setIsAtendimentoFinalizado] = useState(false);
  const [imgZoomUrl, setImgZoomUrl] = useState("");
  const [anexosLocalUrl, setAnexosLocalUrl] = useState<string[]>([]);
  const [anexosLocalUrlDoAtendimento, setAnexosLocalUrlDoAtendimento] =
    useState<string[]>([]);
  const [solicitacaoTrocaAnexosLocalUrl, setSolicitacaoTrocaAnexosLocalUrl] =
    useState<string[]>([]);
  const [valueCarousel, setValueCarousel] = useState(0);
  const [carregandoAnexos, setCarregandoAnexos] = useState(true);

  function handleCloseZoomModal() {
    setIsZoomModalOpen(false);
  }

  const carregarDados = useCallback(async () => {
    await api
      .get(`${OCORRENCIA_JURIDICO_URL}${protocolo}?todosArquivos=true`)
      .then(async (atendimento) => {
        setAtendimento(atendimento.data);
        setCarregando(false);
        setCarregandoAnexos(true);

        if (
          atendimento.data.atendimentoCRMSituacao.nome === "Finalizado" ||
          atendimento.data.emAtendimento === false
        ) {
          history.push(`/transferencia-concluida`);
        }

        if (atendimento.data.anexos?.atendimentoCRMAnexos?.length > 0) {
          let anexosUrls: string[] = [];
          let anexosUrlsDoAtendimento: string[] = [];

          for (const anexo of atendimento.data.anexos.atendimentoCRMAnexos) {
            let response = await fetch(
              `${ANEXO_URL}${anexo.idImagemFileStream}`,
              {
                headers: new Headers({
                  Authorization:
                    "Bearer " + localStorage.getItem("@HavanLabs:token"),
                }),
              }
            );

            if (anexo?.enviadoPorCliente === true) {
              let image = await response.blob();
              anexosUrls.push(URL.createObjectURL(image));
            } else {
              let image = await response.blob();
              anexosUrlsDoAtendimento.push(URL.createObjectURL(image));
            }
          }

          setAnexosLocalUrlDoAtendimento(anexosUrlsDoAtendimento);
          setAnexosLocalUrl(anexosUrls);
        }

        if (atendimento.data.anexos?.solicitacaoTrocaAnexos?.length > 0) {
          let anexosUrls: string[] = [];

          for (const anexo of atendimento.data.anexos.solicitacaoTrocaAnexos) {
            let response = await fetch(
              `${ANEXO_SOL_TROCA_URL}${anexo.idImagemFileStream}`,
              {
                headers: new Headers({
                  Authorization:
                    "Bearer " + localStorage.getItem("@HavanLabs:token"),
                }),
              }
            );

            let image = await response.blob();

            anexosUrls.push(URL.createObjectURL(image));
          }

          setSolicitacaoTrocaAnexosLocalUrl(anexosUrls);
        }
      })
      .finally(() => {
        setCarregandoAnexos(false);
      });
  }, [protocolo, ANEXO_URL, ANEXO_SOL_TROCA_URL, history]);

  useEffect(() => {
    carregarDados();
  }, [carregarDados]);

  const handleChangeNovaAnotacao = useCallback((event) => {
    setNovaAnotacao(event.target.value);
  }, []);

  const zoom = useCallback((url: any, index: number) => {
    setValueCarousel(index);
    setImgZoomUrl(url);
    setIsZoomModalOpen(true);
  }, []);

  const salvarAnexo = useCallback((url: string) => {
    let nomeArquivo = criarNome(20);

    fetch(url).then(async (resp) => {
      const blob = await resp.blob();
      const newBlob = new Blob([blob]);

      const blobUrl = window.URL.createObjectURL(newBlob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", `${nomeArquivo}.jpg`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);

      window.URL.revokeObjectURL(blobUrl);
    });
  }, []);

  const gravarNovaAnotacaoJuridico = useCallback(async () => {
    try {
      setCarregando(true);

      if (novaAnotacao.trim() === "") {
        toast.error("Digite sua anotação no campo 'Nova Anotação'");
        setCarregando(false);
        return;
      }
      if (novaAnotacao.length > 3072) {
        toast.error(
          "A anotação não pode ultrapassar o tamanho de 3072 caracteres"
        );
        setCarregando(false);
        return;
      }

      let anotacao = {
        idAtendimentoCRM: atendimento.id,
        textoAnotacao: novaAnotacao,
      };

      let { data }: { data: IApiResponse } = await api.post(
        "atendimento-crm-juridico/gravar-anotacao-juridico",
        anotacao
      );

      if (!data.sucesso) {
        toast.error(data.message);
        setCarregando(false);
        return;
      }

      await carregarDados();

      setNovaAnotacao("");
      setCarregando(false);
      concluirTransferenciaJuridico();
    } finally {
      setCarregando(false);
    }
  }, [atendimento.id, carregarDados, novaAnotacao]);

  const concluirTransferenciaJuridico = useCallback(async () => {
    try {
      setCarregando(true);
      let concluirAtendimento = {
        idAtendimentoCRM: atendimento.id,
      };

      let { data }: { data: IApiResponse } = await api.post(
        "atendimento-crm-juridico/concluir-atendimento-juridico",
        concluirAtendimento
      );

      if (!data.sucesso) {
        toast.error(data.message);
        return;
      }
      setIsAtendimentoFinalizado(true);
      toast.success("Atendimento Concluído com Sucesso");
      setNovaAnotacao("");
      history.push("/atendimento-crm/juridico");
    } finally {
      setCarregando(false);
    }
  }, [carregarDados, atendimento, history]);

  return (
    <PageContent title="AtendimentoCRM - Jurídico">
      <Container>
        {!isAtendimentoFinalizado && (
          <>
            <ul
              className="nav nav-tabs"
              id="atendimento-crm-juridico-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="hist-jur-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#histJur"
                  type="button"
                  role="tab"
                  aria-controls="histJur"
                  aria-selected="true"
                >
                  Histórico Jurídico
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="anexos-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#anexos"
                  type="button"
                  role="tab"
                  aria-controls="anexos"
                  aria-selected="false"
                >
                  Anexos
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="histJur"
                role="tabpanel"
                aria-labelledby="hist-jur-tab"
              >
                <CardAtendimento atendimento={atendimento} />

                {atendimento?.anotacoesJuridico?.map((anotacao) => (
                  <Anotacao
                    key={anotacao.id}
                    className={
                      anotacao.usuario.codigo === user.codigo
                        ? "anotacaoJuridico"
                        : "anotacaoAtendente"
                    }
                  >
                    <div className="data-tipo">
                      <span>
                        <strong>Data: </strong>
                        {anotacao.dataFormatada}
                      </span>
                    </div>
                    <span className="atendente">
                      <span>
                        <strong>Usuario: </strong>
                        {anotacao.usuario.codigoNome}
                      </span>
                    </span>
                    <div className="descricaoCrm">{anotacao.descricao}</div>
                  </Anotacao>
                ))}

                {atendimento?.atendimentoCRMSituacao?.nome !== "Finalizado" && (
                  <div className="novaAnotacao">
                    <label htmlFor="novaAnotacao">Nova anotação</label>
                    <textarea
                      id="novaAnotacao"
                      name="novaAnotacao"
                      maxLength={3072}
                      value={novaAnotacao}
                      onChange={(event) => handleChangeNovaAnotacao(event)}
                    ></textarea>
                    <LoadingButton
                      carregando={carregando}
                      onClick={() => gravarNovaAnotacaoJuridico()}
                      title="Gravar anotação"
                    >
                      Salvar Anotação
                    </LoadingButton>

                    <div className="botaoConcluir">
                      {/* <LoadingButton
                        carregando={carregando}
                        onClick={() => concluirTransferenciaJuridico()}
                        title="Concluir Transferência"
                        styleButton="btn-danger"
                      >
                        Concluir Transferência
                      </LoadingButton> */}
                    </div>
                  </div>
                )}
              </div>

              <div
                className="tab-pane fade secaoLista"
                id="anexos"
                role="tabpanel"
                aria-labelledby="anexos-tab"
              >
                <>
                  {carregandoAnexos ? (
                    <div className="loading">
                      <ReactLoading
                        type="spin"
                        color="#255c99"
                        height={100}
                        width={100}
                      />
                      <h1 className="text-loading">Carregando anexos...</h1>
                    </div>
                  ) : (
                    <>
                      {anexosLocalUrlDoAtendimento.length > 0 && (
                        <>
                          <p className="titulo-anexo">Anexos do Atendimento</p>
                          <div className="secaoAnexos">
                            {anexosLocalUrlDoAtendimento.map(
                              (anexoUrl, index) => (
                                <div key={index}>
                                  <div className="anexo">
                                    <img
                                      src={anexoUrl}
                                      alt="Anexo"
                                      onClick={() =>
                                        zoom(anexosLocalUrlDoAtendimento, index)
                                      }
                                    />
                                    <LoadingButton
                                      className="btnAnexo salvarBtn"
                                      iconClass="fas fa-download"
                                      carregando={carregando}
                                      onClick={() => salvarAnexo(anexoUrl)}
                                      title="Baixar imagem"
                                    ></LoadingButton>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </>
                      )}

                      {anexosLocalUrl.length > 0 && (
                        <>
                          <div className="separador-anexo">
                            <p className="titulo-anexo">
                              Anexos enviados pelo Cliente
                            </p>
                            <div className="secaoAnexos">
                              {anexosLocalUrl.map((anexoUrl, index) => (
                                <div key={index}>
                                  <div className="anexo">
                                    <img
                                      src={anexoUrl}
                                      alt="Anexo"
                                      onClick={() =>
                                        zoom(anexosLocalUrl, index)
                                      }
                                    />
                                    <LoadingButton
                                      className="btnAnexo salvarBtn"
                                      iconClass="fas fa-download"
                                      carregando={carregando}
                                      onClick={() => salvarAnexo(anexoUrl)}
                                      title="Baixar imagem"
                                    ></LoadingButton>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      )}

                      {solicitacaoTrocaAnexosLocalUrl.length > 0 && (
                        <div className="separador-anexo">
                          <p className="titulo-anexo">
                            Anexos da Solicitação de troca
                          </p>
                          <div className="secaoAnexos">
                            {solicitacaoTrocaAnexosLocalUrl.map(
                              (anexoUrl, index) => (
                                <div key={index}>
                                  <div className="anexo">
                                    <img
                                      src={anexoUrl}
                                      alt="Anexo"
                                      onClick={() =>
                                        zoom(
                                          solicitacaoTrocaAnexosLocalUrl,
                                          index
                                        )
                                      }
                                    />
                                    <LoadingButton
                                      className="btnAnexo salvarBtn"
                                      iconClass="fas fa-download"
                                      carregando={carregando}
                                      onClick={() => salvarAnexo(anexoUrl)}
                                      title="Baixar imagem"
                                    ></LoadingButton>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              </div>
            </div>
          </>
        )}
      </Container>

      <ZoomModal
        isOpen={isZoomModalOpen}
        onRequestClose={handleCloseZoomModal}
        imgUrl={imgZoomUrl}
        imgAlt="Anexo"
        valueCarousel={valueCarousel}
      />
    </PageContent>
  );
};
