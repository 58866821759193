import { Box, Button, Typography, Modal, Backdrop, Fade } from "@mui/material";
import { Wrapper } from "./styles";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #1976d2",
  boxShadow: 24,
  p: 4,
};

interface IPropsModalNotAuthorization {
  openModal: boolean;
  setOpenModal: (args: any) => void;
}

function ModalNotAuthorization({
  openModal,
  setOpenModal,
}: IPropsModalNotAuthorization) {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={() => setOpenModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Pesquisa não realizada.
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Não é permitido filtrar data inferior há 3 meses ou maior que a data atual.
            </Typography>
            <Wrapper>
              <Button
                style={{ alignItems: "flex-end" }}
                onClick={() => setOpenModal(false)}
              >
                Fechar
              </Button>
            </Wrapper>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default ModalNotAuthorization;
