import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  div {
    height: 500px;
    width: 900px;
  }

  .image-rows {
    height: 500px;
    width: 500px;
    padding: 10px 30px;
  }

  .carousel .control-dots .dot {
    background: #ffffff !important;
    opacity: 1;
  }

  .carousel .control-dots .dot.selected {
    background: #1c4470 !important;
    opacity: 1;
  }

  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    opacity: 1;
  }

  .carousel .slide img {
    height: 500px;
    width: 500px;
  }

  .carousel .slider-wrapper {
    background: #f2f2f2;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 8px solid #255c99;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #255c99;
  }

  @media (max-width: 1140px) {
    .image-rows {
      height: 450px;
      width: 450px;
      padding: 10px 30px;
    }
    div {
      max-height: 600px;
      max-width: 700px;
    }
    .carousel .slide img {
     height: 400px;
    }
  }

  @media (max-width: 840px) {
    .image-rows {
      max-height: 400px;
      max-width: 512px;
      padding: 10px 30px;
    }
    div {
      max-height: 400px;
      max-width: 512px;
    }
    .carousel .slide img {
      height: 400px;
    }
  }

  @media (max-width: 540px) {
    .image-rows {
      max-height: 300px;
      max-width: 312px;
      padding: 10px 30px;
    }
    div {
      max-height: 300px;
      max-width: 312px;
    }
    .carousel .slide img {
      height: 300px;
    }
  }
`;
