import styled from "styled-components"
import { transparentize } from "polished"

interface TrProps {
    selected: boolean
    // activeColor: 'selected' | 'nonSelected'
 }
 
 const colors = {
    selected: '#a5b2c9',
    nonSelected: 'rgb(255, 255, 255)'
 }
 
 export const Linha = styled.tr<TrProps>`
 
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: ${(props) => props.selected ? transparentize(0.1, '#a5b2c9') : 'rgb(255, 255, 255)'};
    
 
 ` 