import { Redirect, Switch } from "react-router-dom";
import { Dashboard } from "../pages/Dashboard";
import { SignIn } from "../pages/SignIn";
import { Juridico } from "../pages/AtendimentoCRM/Juridico";
import { Cliente } from '../pages/AtendimentoCRM/Cliente';
import { JuridicoListagem } from "../pages/AtendimentoCRM/JuridicoListagem"
import { NotFoundPage } from "../pages/NotFound/index";
import Route from "./Route";

export const routes = [
  {
    path: "/atendimento-crm/juridico",
    name: "Filtros",
    Component: JuridicoListagem,
    isPrivate: true,
  },
  {
    path: "/atendimento-crm/juridico/:protocolo?",
    name: "AtendimentoCRM - Jurídico",
    Component: Juridico,
    isPrivate: true,
  },
  {
    path: "/atendimento-crm/cliente/:hash?",
    name: "AtendimentoCRM - Cliente",
    Component: Cliente,
    isPrivate: false,
  },
];

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route
        path="/transferencia-concluida"
        component={NotFoundPage}
        isPrivate
      />

      {routes.map(({ path, Component, isPrivate }, key) => (
        <Route
          key={key}
          exact
          path={path}
          component={Component}
          isPrivate={isPrivate}
        />
      ))}

      <Route path="*" component={Dashboard}>
        <Redirect to="/dashboard" />
      </Route>
    </Switch>
  );
};

export default Routes;
