import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import api from "../../../services/api";
import { InformacoesCrm } from "./InformacoesCRM";
import { LinhaTr } from "./Linha";
import {
  Container,
  AreaNovaAnotacao,
  Grid,
  Info,
  NoHashContainer,
  ImageContainer,
  BackgroundLiberdade,
  TabContainer,
  Carregando,
} from "./styles";
import { redimensionaImagem } from "../../../helpers/resizer";
import Loading from "../../../assets/loading.gif";
import "react-toastify/dist/ReactToastify.min.css";

interface ClienteParams {
  hash?: string;
}

interface IAtendimentoAnexos {
  Id: number;
  idAtendimentoCRM: number;
  idImagemFileStream: number;
}

interface IAtendimento {
  id: number;
  nomeCliente: string;
  dddCelular: string;
  celular: string;
  email: string;
  situacaoCrm: string;
  protocoloAtendimento: string;
  codigoPedido: number;
  codigoPedidoMagento: number;
  anotacoes: IAnotacao[];
  anexos: IAtendimentoAnexos;
}

interface IAnotacao {
  id: number;
  idAtendimentoCrm: number;
  origemAnotacao: string;
  nomeAtendenteReduzido: string;
  dataFormatada: string;
  descricao: string;
  lida: number;
  selecionado?: boolean;
}

export const Cliente: React.FC = () => {
  const CRM_CLIENTE_URL = "atendimento-crm-cliente/obter-crm/";
  const ANEXO_URL = `${process.env.REACT_APP_URL}anexos/obter-lista-anexo-crm/`;
  const ANEXO_URL_FILESTREAM = `${process.env.REACT_APP_URL}anexos/buscar-imagem-externa/`;

  const { hash } = useParams() as ClienteParams;

  const [atendimento, setAtendimento] = useState<IAtendimento>(
    {} as IAtendimento
  );

  const [anotacoes, setAnotacoes] = useState<IAnotacao[]>([]);

  const [descricao, setDescricao] = useState<IAnotacao>({} as IAnotacao);

  const [isPreenchido, setIsPreenchido] = useState(false);

  const [isActive, setIsActive] = useState(0);

  const [novaAnotacao, setNovaAnotacao] = useState("");

  const [sucesso, setSucesso] = useState(false);

  const [carregando, setCarregando] = useState(true);

  const [carregandoConsulta, setCarregandoConsulta] = useState(false);

  const [urlImage, setUrlImage] = useState<string[]>([]);

  const [imageArray, setImageArray] = useState<File[]>([]);

  var isImageError = false;

  // 5.242.880 é o valor exato em Bytes referente à 5MB
  const tamanhoMaximo = 5242880;

  // 524288 é o valor exato em metade 500 Bytes referente à 1MB
  const tamanhoMinimo = 524288;

  const carregarDados = useCallback(async () => {
    await api
      .get(`${CRM_CLIENTE_URL}${hash}`)
      .then(async (infos) => {
        setCarregando(false);
        setSucesso(infos.data.sucesso);
        setAtendimento(infos.data.atendimento);
        setAnotacoes(infos.data.atendimento.anotacoes);
        carregarrListaImagens(
          infos.data.atendimento.anexos.atendimentoCRMAnexos
        );
      })
      .catch((err) => console.log(err));
  }, []);

  const buscarAnexos = useCallback(async () => {
    await api
      .get(`${CRM_CLIENTE_URL}${hash}`)
      .then(async (infos) => {
        carregarrListaImagens(
          infos.data.atendimento.anexos.atendimentoCRMAnexos
        );
      })
      .catch((err) => console.log(err));
  }, []);

  const salvarAnexo = useCallback(async (id: number, listImg: any) => {
    if (isImageError == false) {
      const dados = new FormData();

      for (let i = 0; i < listImg.length; i++) {
        dados.append("Arquivos", listImg[i]);
      }

      setCarregandoConsulta(true);

      await fetch(
        `${process.env.REACT_APP_URL}anexos/cadastrar-externa-varios/${id}`,
        {
          method: "POST",
          headers: new Headers({
            Authorization: `${process.env.REACT_APP_TOKEN_API}`,
          }),
          body: dados,
        }
      )
        .then((resonse) => resonse.json())
        .then((data) => {
          toast.success("Imagem(s) salva(s) com sucesso");
          setUrlImage([]);
          buscarAnexos();
          setCarregandoConsulta(false);
        })
        .catch((err) => console.log(err));
    } else {
      console.log("teste");
    }
  }, []);

  const carregarrListaImagens = useCallback(
    async (listaAnexos: IAtendimentoAnexos[]) => {
      listaAnexos.forEach((anexo) => {
        fetch(`${ANEXO_URL_FILESTREAM}${anexo.idImagemFileStream}`, {
          headers: new Headers({
            Authorization: `${process.env.REACT_APP_TOKEN_API}`,
          }),
        })
          .then((response) => response.blob())
          .then((data) => URL.createObjectURL(data))
          .then((url) => setUrlImage((oldValue) => [...oldValue, url]))
          .catch((err) => console.log(err));
      });
    },
    []
  );

  function trSelecionada(anotacao: IAnotacao) {
    let anotacaoSelecionada: IAnotacao = {
      ...anotacao,
      selecionado: true,
    };
    setDescricao(anotacaoSelecionada);
    setIsPreenchido(true);
  }

  async function gravaMensagemCliente() {
    if (novaAnotacao.trim() === "") {
      toast.error("Digite sua anotação no campo 'Nova Anotação'");
      setCarregando(false);
      return;
    }
    if (novaAnotacao.length > 3072) {
      toast.error(
        "A anotação não pode ultrapassar o tamanho de 3072 caracteres"
      );
      setCarregando(false);
      return;
    }

    let obj = {
      idAtendimentoCrm: atendimento.id,
      mensagem: novaAnotacao,
    };

    await api.post("/atendimento-crm-cliente", obj).then(async (infos) => {
      setAnotacoes((oldvalues) => [...oldvalues, infos.data.anotacao]);
      setNovaAnotacao("");
    });
  }

  const validaImagens = async (list: FileList) => {
    const arrayImagensValidas: File[] = [];

    let extensoesPermitidas = ["jpg", "png", "jpeg"];

    for (let item = 0; item < list.length; item++) {
      let fileIterator = list.item(item);
      let extensao = fileIterator?.type.split("/")[1];

      if (
        extensoesPermitidas.findIndex((element) => element === extensao) === -1
      ) {
        toast.error(
          "Extensão inválida, por favor anexe uma imagem que tenha como extensões: .JPG, .JPEG, .PNG"
        );
        break;
      }

      if (fileIterator?.size!! >= tamanhoMaximo) {
        toast.error(
          "Tamanho máximo excedido, por favor anexe uma imagem que tenha até 5MB de tamanho"
        );
        setCarregandoConsulta(false);

        break;
      }

      if (fileIterator?.size!! >= tamanhoMinimo) {
        const imgResized = await redimensionaImagem(fileIterator!!, extensao!!);
        arrayImagensValidas.push(imgResized);
      } else {
        arrayImagensValidas.push(fileIterator!!);
      }
    }

    return arrayImagensValidas;
  };

  async function handleUploadImagem(fileList: FileList) {
    let imgList = fileList;

    if (imgList.length > 5) {
      return toast.error(
        "Quantidade de imagens excedida! \nPermitido anexar apenas 5 imagens"
      );
    }

    if (imgList.length > 1) {
      const imagensValidas = await validaImagens(imgList);
      if (imagensValidas.length === 0) {
        setCarregandoConsulta(false);
        return;
      }
      await salvarAnexo(atendimento.id, imagensValidas);
    } else {
      const imagemValida = await validaImagens(imgList);
      if (imagemValida.length === 0) {
        setCarregandoConsulta(false);
        return;
      }
      await salvarAnexo(atendimento.id, imagemValida);
    }
  }

  const handleChangeNovaAnotacao = useCallback((event) => {
    setNovaAnotacao(event.target.value);
  }, []);

  useEffect(() => {
    carregarDados();
  }, [imageArray]);

  return (
    <BackgroundLiberdade>
      <Container>
        {carregando ? (
          <ImageContainer>
            <img src={Loading} alt="Loading" />
          </ImageContainer>
        ) : hash === undefined || !sucesso ? (
          <NoHashContainer>
            <h5>
              <strong>
                Não foi possível localizar um atendimento com esse protocolo.
              </strong>
            </h5>
          </NoHashContainer>
        ) : (
          <>
            <div className="content_client">
              <InformacoesCrm atendimento={atendimento} />

              <Grid>
                <h5>Outras anotações</h5>
                <a className="show-grid">
                  Outras anotações<span>&nbsp;</span>
                </a>
                <div className="hvn-grid">
                  <table>
                    <thead>
                      <tr>
                        <th>Data</th>
                        <th>Tipo</th>
                      </tr>
                    </thead>
                    <tbody className="line hv">
                      {anotacoes.map((ant) => (
                        <LinhaTr
                          key={ant.id}
                          anotacao={ant}
                          setIsActive={(anotacao) => {
                            setIsActive(ant.id);
                            trSelecionada(anotacao);
                          }}
                          isActive={isActive === ant.id}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </Grid>
            </div>

            {carregandoConsulta === true ? (
              <Carregando>
                <Spinner animation="border" role="status" variant="primary" />
              </Carregando>
            ) : (
              <TabContainer>
                <ul
                  className="nav nav-tabs"
                  id="atendimento-crm-juridico-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="anotacoes-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#anotacoes"
                      type="button"
                      role="tab"
                      aria-controls="anotacoes"
                      aria-selected="true"
                    >
                      Anotações
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="anexos-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#anexos"
                      type="button"
                      role="tab"
                      aria-controls="anexos"
                      aria-selected="false"
                    >
                      Anexos
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="anotacoes"
                    role="tabpanel"
                    aria-labelledby="anotacoes-tab"
                  >
                    {isPreenchido && (
                      <Info>
                        <h5>
                          {descricao.origemAnotacao === "Retorno ao cliente"
                            ? `Atendente: ${descricao.nomeAtendenteReduzido}`
                            : "Cliente"}
                        </h5>
                        <div className="anotacao-selecionada">
                          <span>{descricao.descricao}</span>
                        </div>
                      </Info>
                    )}

                    {atendimento.situacaoCrm === "Finalizado" ? (
                      <></>
                    ) : (
                      <AreaNovaAnotacao>
                        <form>
                          <fieldset>
                            <legend>Nova mensagem</legend>
                            <span className="ta">
                              <textarea
                                name="Anotacao"
                                id="Anotacao"
                                rows={5}
                                value={novaAnotacao}
                                onChange={(event) =>
                                  handleChangeNovaAnotacao(event)
                                }
                              ></textarea>
                            </span>
                            <input
                              className="btn-primary"
                              type="button"
                              value="Enviar"
                              onClick={() => gravaMensagemCliente()}
                            />
                          </fieldset>
                        </form>
                      </AreaNovaAnotacao>
                    )}
                  </div>

                  <div
                    className="tab-pane fade secaoLista"
                    id="anexos"
                    role="tabpanel"
                    aria-labelledby="anexos-tab"
                  >
                    <span>
                      OBS*: Permitido selecionar até 5 arquivos por vez e apenas
                      jpg, jpeg, png!
                    </span>
                    <div className="secaoAnexos">
                      {urlImage.map((anexoUrl, index) => (
                        <div key={index}>
                          <div className="anexo">
                            <img src={`${anexoUrl}`} alt="Anexo" />
                          </div>
                        </div>
                      ))}
                    </div>
                    <label className="btn-file">
                      <input
                        type="file"
                        onChange={(e) => handleUploadImagem(e.target.files!!)}
                        multiple
                      />
                      <i className="fas fa-paperclip" /> Anexar
                    </label>
                  </div>
                </div>
              </TabContainer>
            )}
          </>
        )}
      </Container>
    </BackgroundLiberdade>
  );
};
