import Modal from 'react-modal';
import { Carousel } from "react-responsive-carousel";

import { Container } from "./styles";
interface ZoomModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  imgUrl: any;
  imgAlt: string;
  onChangeCarousel?: (args: any) => void;
  valueCarousel?: number;
}

Modal.setAppElement('#root')

export function ZoomModal({
  isOpen,
  onRequestClose,
  imgUrl,
  imgAlt,
  onChangeCarousel,
  valueCarousel,
}: ZoomModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <i className="fas fa-times" style={{ color: "white" }} />
      </button>

      <Container>
        <div>
          {imgUrl && (
            <Carousel
              showStatus={false}
              selectedItem={valueCarousel}
              showThumbs={false}
              showArrows={true}
              infiniteLoop={true}
            >
              {imgUrl.map((anexoUrl: string, index: any) => (
                <img
                  className="image-rows"
                  key={index}
                  src={anexoUrl}
                  alt={imgAlt}
                />
              ))}
            </Carousel>
          )}
        </div>
      </Container>
    </Modal>
  );
};
