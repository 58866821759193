import styled from "styled-components";

interface ShadowedProps {
  readonly criticidade: string | null | undefined;
};

export const ContainerFilter = styled.div`
  background: #ffffff;
  margin-bottom: 4px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  .client-textfield {
    width: 510px;
  }

  .simple-textfield {
    width: 180px;
  }

  .date-textfield {
    width: 150px;
  }

  @media (max-width: 580px) {
    .client-textfield {
      width: 90%;
    }
  }

  @media (max-width: 412px) {
    .client-textfield {
      width: 90%;
    }

    .simple-textfield {
      width: 90%;
    }
  }
`;

export const SeparatorColor = styled.div<ShadowedProps>`
  background: ${(props: any) =>
    props?.criticidade === "Alta"
      ? "#E83F5B"
      : props?.criticidade === "Média"
      ? "#FEE000"
      : "#12A454"};
  height: 1rem;
  width: 4px;
  margin-right: 8px;
`;

export const TextTableCriticidade = styled.h1`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: rgba(0, 0, 0, 0.87);
`;

export const TitleTable = styled.span`
  font-weight: bold;
  color: #2e323d;
`;
