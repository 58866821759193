import { Linha } from "./styles";

interface Anotacao {
    id: number,
    idAtendimentoCrm: number,
    origemAnotacao: string,
    nomeAtendenteReduzido: string,
    dataFormatada: string,
    descricao: string,
    lida: number;
    selecionado?: boolean;
}

interface AnotacaoData {
    anotacao: Anotacao,
    setIsActive: (anotacao: Anotacao) => void;
    isActive: boolean;
}

export function LinhaTr({ anotacao, setIsActive, isActive }: AnotacaoData) {

    return (
        <Linha
            key={anotacao.id}
            selected={isActive}
            onClick={() =>setIsActive(anotacao)}
        >

            <td>{anotacao.dataFormatada}</td>
            <td>{anotacao.origemAnotacao}</td>
        </Linha>
    );
}